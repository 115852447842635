<template>
  <LayoutDefault>
    <Loading :active="loading" />
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper news">
      <!-- Content Header (Page header) -->
      <div class="content-header mt-2">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-xs-12 col-sm-6 mb-3 mb-md-0">
              <h1 class="m-0 text-dark ttU">Noticias</h1>
            </div>
            <!-- /.col -->
            <div class="col-xs-12 col-sm-6">
              <router-link
                :to="{ name: 'NewsCreate' }"
                class="btn btn-default btn-default-invert float-right ml-2 mb-2 mb-md-0"
              >
                Crear Noticia
              </router-link>
            </div>
          </div>
          <!-- /.row -->
        </div>
        <!-- /.container-fluid -->
      </div>
      <!-- /.content-header -->

      <!-- Main content -->

      <section class="content">
        <div class="container-fluid">
          <div class="row row-cols-1 row-cols-md-3" v-if="!loading">
            <div
              class="col-xs-12 col-sm-6 col-md-4"
              v-for="(el, index) in news"
              :key="index"
            >
              <!-- CARD -->
              <div class="card card--horizontal mb-3">
                <div
                  class="row no-gutters"
                  style="max-width: 540px; height: 175px"
                >
                  <div class="col-md-4">
                    <img
                      :src="el.image"
                      class="card-img"
                      alt=""
                    />
                  </div>
                  <div class="col-md-8">
                    <div class="card-body">
                      <h5 class="card-title mb-1">{{ el.title }}</h5>
                      <p class="card-text mt-1">{{ el.description }}</p>
                    </div>
                  </div>
                </div>
                <router-link
                  :to="{ name: 'NewsEdit', params: { new_id: el.id } }"
                  class="btn-edit btn btn-info"
                >
                  <i class="nav-icon fas fa-edit"></i>
                </router-link>
                <button
                  @click="deleteNew(el.id)"
                  class="btn-delete btn btn-danger"
                >
                  <i class="nav-icon fas fa-trash"></i>
                </button>
              </div>
              <!-- END CARD -->
            </div>
          </div>
          <p v-if="news.length === 0 && loading === false">
            No hay ninguna noticia creada
          </p>
          <p v-if="loading">Cargando...</p>
        </div>
        <!-- /.container-fluid -->
      </section>
      <!-- /.content -->
    </div>
  </LayoutDefault>
</template>
<script>
import LayoutDefault from "../layout/LayoutAdmin";
import newsService from "@/services/newsService";
import config from "@/config/config";
import Loading from "../components/Loading";
export default {
  name: `News`,
  data() {
    return {
      news: [],
      config: config.getConfig(),
      loading: true,
    };
  },
  async mounted() {
    this.loading = true;
    await this.getAllNews();
    this.loading = false;
  },
  methods: {
    async getAllNews() {
      const res = await newsService.getAllNews();
      if (res && res.response) {
        this.news = res.response;
        if (res.response.data) {
          this.news = res.response.data;
        }
      }
    }, // 1
    async deleteNew(id) {
      this.loading = true;
      await newsService.deleteNew(id);
      await this.getAllNews();
      this.loading = false;
    },
  },
  components: {
    LayoutDefault,
    Loading,
  },
};
</script>
<style lang="scss" scoped>
.btn-edit {
  position: absolute;
  right: 0.5em;
  bottom: 0.5em;
}
.btn-delete {
  position: absolute;
  right: 0.5em;
  top: 0.5em;
}
</style>
